export const AUTH='AUTH';
export const LOGOUT='LOGOUT';
export const SIGNUP='SIGNUP'
export const GET_PRODUCTS='GET_PRODUCTS'
export const GET_PRODUCT='GET_PRODUCT'
export const GET_BY_SEARCH='GET_BY_SEARCH'
export const GET_PRODUCT_NAMES='GET_PRODUCT_NAMES'
export const FETCH_PRODUCTS = "FETCH_PRODUCTS"
export const FETCH_CATEGORIES = "FETCH_CATEGORIES"
export const FETCH_SUB_CATEGORIES = "FETCH_SUB_CATEGORIES"
export const FETCH_USERS = "FETCH_USERS"
export const FETCH_USER_COUNT = "FETCH_USER_COUNT"
export const START_LOADING = "START_LOADING"
export const END_LOADING = "END_LOADING"
export const POST_PRODUCT = "POST_PRODUCT"
export const UPDATE_PRODUCT = "UPDATE_PRODUCT"
export const DELETE_PRODUCT = "DELETE_PRODUCT"
export const POST_SUPPLIER = "POST_SUPPLIER"
export const POST_CATEGORY = "POST_CATEGORY"
export const POST_SUB_CATEGORY = "POST_SUB_CATEGORY"
export const END_LOADING_AUTH='END_LOADING_AUTH'
export const START_LOADING_AUTH='START_LOADING_AUTH'