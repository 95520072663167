import { END_LOADING, FETCH_PRODUCTS, GET_BY_SEARCH, GET_PRODUCT, GET_PRODUCTS, GET_PRODUCT_NAMES, POST_PRODUCT, POST_SUPPLIER, START_LOADING } from "../constants/actionTypes";

const initialState = {
    supplier:"",
    isLoading: true,
}
const supplierReducer = (state = initialState, action)=>{
    switch (action.type) {
        case POST_SUPPLIER:
            return {...state, supplier: action.payload}
        case START_LOADING:
            // console.log("hhh")
            return {...state, isLoading : true}
        case END_LOADING:
            // console.log("ggg")
            return {...state, isLoading : false}
        default:
            return state;
    }
}

export default supplierReducer