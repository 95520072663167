import { combineReducers } from "redux";
import auth from './auth.js'
import products from './products.js'
import category from "./category.js";
import supplier from "./supplier.js";
import order from "./order.js";


export default combineReducers({
    auth:auth,
    products:products,
    category:category,
    supplier:supplier,
    order:order,
})