import { DELETE_PRODUCT, END_LOADING, FETCH_PRODUCTS, GET_BY_SEARCH, GET_PRODUCT, GET_PRODUCTS, GET_PRODUCT_NAMES, POST_PRODUCT, START_LOADING, UPDATE_PRODUCT } from "../constants/actionTypes";

const initialState = {
    products: [],
    count:0,
    isLoading: true,
    names:"",
    product: {},
}
const productReducer = (state = initialState, action)=>{
    switch (action.type) {
        case FETCH_PRODUCTS:
        case GET_PRODUCTS:
            return {...state, products: action.payload.data, count:action.payload.count};
        case POST_PRODUCT:
            return {...state, products: [...state.products, action.payload]}
        case UPDATE_PRODUCT:
            return {...state, products: state.products.map((product)=>product._id===action.payload._id ? action.payload : product)}
        case DELETE_PRODUCT:
            return {...state, products: state.products.filter((product)=>product._id!==action.payload)}
        case GET_PRODUCT:
            return {...state, product: action.payload.data};
        // case GET_MOSTLY_RENTED_PRODUCTS:
        //     return {...state, products: action.payload.data};
        case GET_PRODUCT_NAMES:
            return {...state, names: action.payload.data};
        case GET_BY_SEARCH:
            return {...state, products: action.payload.data};
        case START_LOADING:
            // console.log("hhh")
            return {...state, isLoading : true}
        case END_LOADING:
            // console.log("ggg")
            return {...state, isLoading : false}
        default:
            return state;
    }
}

export default productReducer