import axios from 'axios';

const API = axios.create({baseURL: 'https://server.rentomart.in/'})

API.interceptors.request.use((req)=>{
    if(localStorage.getItem('profile'))
        req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`

        return req;
})
// this function will run like a middleware for other requests


export const logIn = (formData) => API.post('/user/login',formData)
export const signUp = (formData) => API.post('/user/signup',formData)
export const verify = (code, authData) => API.get(`/user/${authData.result._id}/verify/${code}`)



// Products

export const getProduct = (id) => API.get(`/product/${id}`);
export const getProductNames = () => API.get(`/product/product-names`);
export const getProductsBySearch = (searchQuery) => API.get(`/product/search?searchQuery=${searchQuery.search || 'none'}`);


// export const getProducts = () => API.get(`/admin/products`);
export const getCategories = () => API.get(`/product/categories`);
export const getSubCategories = () => API.get(`/product/sub-categories`);
export const postCategory = (data) => API.post(`/admin/post/category`,data);
export const postSubCategory = (data) => API.post(`/admin/post/sub-category`,data);
export const getUsers = () => API.get(`/admin/users`);
export const getUserCount = () => API.get(`/admin/user-count`);

//Supplier
export const getProducts = () => API.get(`/supplier/get/products`);
export const postSupplier = (data) => API.post(`/supplier/post/new`,data);
export const postProduct = (data) => API.post(`/supplier/post/products`,data);
export const updateProduct = (data, id) => API.patch(`/supplier/product/${id}`,data);
export const deleteProduct = (id) => API.delete(`/supplier/product/${id}`);
export const getSupplierOrders = () => API.get(`/supplier/get/order`);
