import React from 'react'

const RentalCategories = () => {
  return (
    <>
    <section className="product spad py-3 bg2" id="service">
    <div className="container">
        <h2 className="section-title text-white">Rental Categories</h2>
        <div className="row product__filter justify-content-center">
            
            <div className="col-lg-2 col-md-4 col-6">
                <div className="card mb-3">
                    <a href="smart-devices.php">
                    <img src={process.env.PUBLIC_URL + "/img/vector/smart-devices.jpg"} alt=""/>
                    <h5 className="text-center my-cateogries">Smart Devices</h5></a>
                </div>                    
            </div>
            <div className="col-lg-2 col-md-4 col-6">
                <div className="card mb-3">
                    <img src={process.env.PUBLIC_URL + "/img/vector/jewellery.jpg" }alt=""/>
                    <a href="jewellery.php"><h5 className="text-center my-cateogries">Jewellery</h5></a>
                </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6">
                <div className="card mb-3">
                    <a href="outfit.php">
                    <img src={process.env.PUBLIC_URL + "/img/vector/outfit.jpg" }alt=""/>
                    <h5 className="text-center my-cateogries">Outfit</h5></a>
                </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6">
                <div className="card mb-3">
                    <a href="home-appliance.php">
                    <img src={process.env.PUBLIC_URL + "/img/vector/home-appliance.jpg"} alt=""/>
                    <h5 className="text-center my-cateogries">Home Appliance</h5></a>
                </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6">
                <div className="card mb-3">
                    <a href="furniture.php">
                    <img src={process.env.PUBLIC_URL + "/img/vector/furniture.jpg" }alt=""/>
                    <h5 className="text-center my-cateogries">Furniture</h5></a>
                </div>                    
            </div>
            <div className="col-lg-2 col-md-4 col-6">
                <div className="card mb-3">
                    <a href="electrical-machinery.php">
                    <img src={process.env.PUBLIC_URL + "/img/vector/electrical-machinery.jpg"} alt=""/>
                    <h5 className="text-center my-cateogries">Electrical/Machinery</h5></a>
                </div>
            </div>
        </div>
    </div>
</section>
    </>
  )
}

export default RentalCategories
