import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProducts } from '../actions/product';
import { Avatar, Button, CircularProgress, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import './ProductTable.scss'
import { useNavigate } from 'react-router-dom';
import { deleteProduct } from '../actions/deleteData';
import { getSupplierOrders } from '../actions/order';

const OrderTable = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const products = [1,2,3]
    const { supplierOrders, isLoading } = useSelector((state) => state.order)
    useEffect(() => {
        !supplierOrders.length && dispatch(getSupplierOrders())
    }, [])
    // console.log(products)

    // const handleDelete = (id) => {
    //   dispatch(deleteProduct(id))
    // }


    return (isLoading ? <CircularProgress/> :
        <div style={{overflowX:"auto"}}>
  <table class="table table-borderless">
  <thead>
    <tr>
        
      <th scope="col">Product</th>
      <th scope="col">Quantity</th>
      <th scope="col">Deposited</th>
      <th scope="col">Amount</th>
      <th scope="col">Pay Status</th>
      <th scope="col">Customer Name</th>
      <th scope="col">Rented At</th>
      <th scope="col">Returning Date</th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
    {supplierOrders.map((p)=>(
    <tr key={p._id}>
      <td>{p.productName}</td>
      <td>{p.quantity}</td>
      <td>{p.deposit}</td>
      <td>{p.amount}</td>
      <td>{p.payStatus}</td>
      <td>{p.customerName}</td>
      <td>{p.rentalDate.substr(0,10)}</td>
      <td>{p.plannedReturningDate.substr(0,10)}</td>
      <td> <Button variant="contained" onClick={()=>{navigate(`/products/${p.productId}`)}} color="info" sx={{width:40}} >View</Button> </td>
      {/* <td> <IconButton color="error"  onClick={()=>handleDelete(p._id)}> <DeleteIcon/> </IconButton> </td> */}
    </tr>
))}
  </tbody>
</table>
</div>
    );
}

export default OrderTable