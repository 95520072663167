import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProducts } from '../actions/product';
import { Avatar, Button, CircularProgress, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import './ProductTable.scss'
import { useNavigate } from 'react-router-dom';
import { deleteProduct } from '../actions/deleteData';

const ProductTable = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { products, product, count, isLoading } = useSelector((state) => state.products)
    useEffect(() => {
        !products.length && dispatch(getProducts())
    }, [])
    // console.log(products)

    const handleDelete = (id) => {
      dispatch(deleteProduct(id))
    }


    return (isLoading ? <CircularProgress/> :
        <div style={{overflowX:"auto"}}>
  <table class="table table-borderless">
  <thead>
    <tr>
        
      <th scope="col">Product</th>
      <th scope="col">Availability</th>
      <th scope="col">Status</th>
      <th scope="col">Sub Category</th>
      <th scope="col">Stock</th>
      <th scope="col">Monthly Cost</th>
      <th scope="col">Weekly Cost</th>
      <th scope="col">Featured</th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
    {products.map((p)=>(
    <tr key={p._id}>
      <td>{p.productName}</td>
      <td  >
        <div className={p.availability.split(" ")[0]}></div>
      </td>
      <td>{p.status}</td>
      <td>{p.subCategory}</td>
      <td>{p.noOfStock}</td>
      <td>{p.costPerMonth}</td>
      <td>{p.costPerMonth}</td>
      <td>{p.featured}</td>
      <td> <Button variant="contained" onClick={()=>{navigate(`/products/${p._id}`)}} color="info" sx={{width:40}} >Edit</Button> </td>
      <td> <IconButton color="error"  onClick={()=>handleDelete(p._id)}> <DeleteIcon/> </IconButton> </td>
    </tr>
))}
  </tbody>
</table>
</div>
    );
}

export default ProductTable