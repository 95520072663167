import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { login, verify } from '../actions/auth'
import { CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';



const Login = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [verified, setVerified] = useState(true)
    const [code, setCode] = useState()
    const {isLoading} = useSelector((state)=>state.auth)



    const initialState = {
        email:"",
        password:"",
    }


    const [authData, setAuthData] = useState(initialState)

    const handleChange = (e) =>{
        setAuthData({...authData, [e.target.name]:e.target.value})
    }

    const handleSubmit = (e)=>{
        e.preventDefault()
        dispatch(login(authData, navigate, setVerified))
    }
    const handleChange2 = (e) =>{
        setCode(e.target.value)
    }

    const handleVerify = (e) => {
        e.preventDefault()
        console.log(code)
        console.log(authData)
        dispatch(verify(code, navigate))
    }

    const [width, setWidth] = useState(window.innerWidth);

const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
}
useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
}, []);

const isMobile = width <= 700;
  return (
    <>
        <br/>
            <section>
               {verified ? (<div className="container" >
                   <div className="sign-form px-auto" style={isMobile?{border:'none',marginLeft:'7%',marginRight:'7%',width:'50vw'}:{}}>
                       <h2 style={isMobile?{marginLeft:'50%'}:{}} >Login</h2>
                   <form onSubmit={handleSubmit} style={isMobile ? { width: "150%" } : {}}>
                       <div className="row">
                           <div className="col-lg-12">
                               <input style={{color:'#333'}} type="email" name="email" id="email" placeholder="Email Id" onChange={handleChange}/>
                           </div>
                           <div className="col-lg-12">
                               <input style={{color:'#333'}} type="password" name="password" id="password" placeholder="Password" onChange={handleChange}/>
                           </div>
                           <div className="offset-lg-4 col-lg-4 justify-content-center">
                               <button type="submit" className="site-btn">Log In &nbsp; {isLoading && <CircularProgress style={{position:'absolute',color:'white'}}  size={25} disableShrink />} </button>
                           </div>
                       </div>
                           <div>
            <Typography style={{marginTop:'1em'}} >Don't have an account? <Link style={{textDecoration:"none",color:'0x0B64BD',fontWeight:'bold'}} to={"/signup"}> Click here to register </Link> </Typography>
           </div>
                       
                   </form>
                   </div>
               </div>) : (<div className="container">
               <div className="sign-form px-4" style={isMobile?{border:'none',marginLeft:'7%',marginRight:'7%',width:'65vw'}:{}} >
                   <h2 style={isMobile?{marginLeft:'20%',width:'100%'}:{}} >User Verification</h2>
               <form onSubmit={handleVerify} style={isMobile ? { width: "150%" } : {}} >
                   <p className = "text-center  ">A verification code has been sent to {authData.email}</p>
                   <p className = "text-center  ">If you didn't find the mail in your inbox then please check your spam folder</p>
                   <div className="row">
                       <div className="col-lg-12">
                           <input style={{color:'#333'}} type="text" name="Verification Code" id="verify" placeholder="Verification Code" onChange={handleChange2}/>
                       </div>
                       <div className="offset-lg-4 col-lg-4 justify-content-center">
                           <button type="submit" className="site-btn">Verify &nbsp; {isLoading && <CircularProgress style={{position:'absolute',color:'white'}}  size={25} disableShrink />} </button>
                       </div>
                   </div>
               </form>
               </div>
               
           </div>)}
            </section>
       <br/>
    </>
  )
}

export default Login
