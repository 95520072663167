import Footer from "./components/Footer";
import Header from "./components/Header";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import Signup from "./components/Signup";
import Home from "./components/Home";
import Profile from "./components/Profile";
import CustomerForm from "./components/CustomerForm";
import KycForm from "./components/KycForm";
import SupplierForm from "./components/SupplierForm";
import CategoryForm from "./components/CategoryForm";
import ProductForm from "./components/ProductForm";
import SingleProduct from "./components/SingleProduct";
import Products from "./components/Products";
import ProductTable from "./components/ProductTable";
import OrderTable from "./components/OrderTable";


function App() {
  
  return (
    <BrowserRouter>
      <Header/>
      <Routes>
        <Route path='/'>
          <Route index element={<Home/>}/>
          <Route path='/orders' element={<OrderTable/>}/>
          <Route path='products'>
            <Route index element={<ProductTable/>}/>
            <Route path=":productId" element={<SingleProduct/>}/>
            <Route path='search' element={<Products/>}/>
            <Route path="product-form" element={<ProductForm/>}/>
          </Route>
          <Route path="login" element={<Login/>}/>
          <Route path="signup" element={<Signup/>}/>
          <Route path="profile" element={<Profile/>}/>
          <Route path="kyc-form" element={<KycForm/>}/>
          <Route path="supplier-form" element={<SupplierForm/>}/>
        </Route>
      </Routes>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
