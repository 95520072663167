import * as api from '../api';
import { DELETE_PRODUCT } from "../constants/actionTypes";

export const deleteProduct = (id) => async(dispatch) =>{
    try{
        await api.deleteProduct(id);
        dispatch({type:DELETE_PRODUCT,payload: id});
    }
    catch(err)
    {
        console.log(err.message);
    }
}