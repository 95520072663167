import { AUTH,  END_LOADING_AUTH,   START_LOADING_AUTH } from '../constants/actionTypes.js';
import * as api from '../api';

export const login = (authData, navigate, setVerified) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING_AUTH })
        const { data } = await api.logIn(authData);

        // console.log(data)



        dispatch({ type: AUTH, data })

        dispatch({ type: END_LOADING_AUTH })

        navigate('/')
    } catch (error) {
        alert(error.response.data.message)
        if (error.response.data.message === "An Email sent to your account please verify") {
            setVerified(false)
            const data = error.response.data
            console.log(data)
            dispatch({ type: AUTH, data })
        }
    }
}

export const signup = (authData, navigate) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING_AUTH })
        const { data } = await api.signUp(authData);
        // console.log("Hello", data)

        // alert(`And Email has been sent on Your account ${data?.result?.email}`)
        dispatch({ type: AUTH, data })
        dispatch({ type: END_LOADING_AUTH })
        // navigate('/') 
    } catch (error) {
        console.log(error)
    }
}

export const verify = (code, navigate) => async (dispatch) => {
    try {
        const authData = JSON.parse(localStorage.getItem('unverified_profile'))
        // console.log(authData)
        // console.log("Hellllloooooo")
        // console.log(authData.result._id)
        dispatch({ type: START_LOADING_AUTH })
        const { data } = await api.verify(code, authData);
        // alert(data.message)
        // console.log(data)
        dispatch({ type: AUTH, data })
        dispatch({ type: END_LOADING_AUTH })
        // dispatch({type:SIGNUP})
        navigate('/')
    } catch (error) {
        console.log(error)
    }
}


