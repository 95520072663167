import * as api from '../api';
import { END_LOADING_ORDERS, GET_SUPPLIER_ORDERS, START_LOADING_ORDERS } from "../constants/orderConstants"

export const getSupplierOrders = () => async(dispatch) => {
    dispatch({type:START_LOADING_ORDERS})
    const {data} = await api.getSupplierOrders()
    dispatch({type:GET_SUPPLIER_ORDERS,payload:data.orders})
    dispatch({type:END_LOADING_ORDERS})

}
