import React, { useEffect, useState } from 'react'
import { Checkbox, CircularProgress, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Select } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { getCategories, getSubCategories } from '../actions/fetchData'
import { postProduct } from '../actions/postData'
import FileBase64 from 'react-file-base64'
import "./productForm.css"
import { useNavigate } from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/Delete';




const ProductForm = () => {

  const user = JSON.parse(localStorage.getItem('profile'))

  const navigate = useNavigate()
  useEffect(() => {
    if (!user || !user.result)
      navigate('/login')

  }, [])

  const initialState = {
    productName: "",
    availability: "In Stock",
    category: "Other",
    subCategory: "Other",
    address: "",
    noOfStock: "",
    deposit: "",
    costPerWeek: "",
    costPerMonth: "",
    productImages: [],
    description: "",
    status: "",
    brand: "",
    featured: "",
    deliveryCharges: ""
  }
  const [checked, setChecked] = useState(false)
  const [data, setData] = useState(initialState)
  const dispatch = useDispatch()

  const { subCategories, categories, isLoading, countC, countS } = useSelector((state) => state.category)



  // data.subCategory = subCategories?.find((c) => (c.categoryName === data.category))
  // data.subCategory = data.subCategory?.subCategoryName


  let subC;
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
    // if(e.target.name==='category')
    // {
    //   subC = subCategories?.find((c) => (c.categoryName === data.category))
    //   setData({...data,subCategory:subC.subCategoryName})
    // }
  }
  const handleSubmit = (e) => {
    e.preventDefault()


    // console.log(productImages)
    try {
      // console.log(productImages)
      // console.log(data)

      if (
        !data.productName ||
        !data.availability ||
        !data.category ||
        !data.subCategory ||
        !data.address ||
        !data.noOfStock ||
        !data.costPerWeek ||
        !data.costPerMonth ||
        !data.productImages ||
        !data.description ||
        !data.status ||
        !data.brand ||
        !data.deposit ||
        !data.deliveryCharges ||
        !data.featured
      ) {
        alert("All fields must be filled")
        console.log(data)
      }
      else {
        console.log(data)
        dispatch(postProduct(data, setData))
        setData(initialState)
        // setProductImages([])
        // setImages([])
      }
    } catch (error) {
      console.log(error)
    }
  }
  const handleImageDelete = (item) => {

    setData({ ...data, productImages: data.productImages.filter((i)=>i!==item) })
  }

  const [width, setWidth] = useState(window.innerWidth);

const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
}
useEffect(() => {
  countC===0 && dispatch(getCategories())
  countS===0 && dispatch(getSubCategories())
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
}, []);

const isMobile = width <= 700;

  return (
    isLoading ? <CircularProgress /> : <>
      <br />
      <section >
        <div className="container">
            <div className="row d-flex justify-content-center">
        <div className="col-md-4 py-4">
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  {data.productImages && data.productImages.map((item, i) => (
                    <>
                      <div style={{ display: "flex" }}>

                        <img
                          style={{ width: '50vh', margin: '5px' }}
                          key={i}
                          src={item}
                          alt={`${i} Slide`}
                        />
                        <IconButton color="error" sx={{ borderRadius: 0 }} onClick={() => handleImageDelete(item)}> <DeleteIcon /> </IconButton>
                      </div>
                    </>


                  ))}
                </div>
        </div>
        <div className="col-md-8 py-4">
          <div className={`sign-form px-2 ${isMobile?'':'mr-0'}`} style={isMobile?{border:'none',marginLeft:'7%',marginRight:'7%',width:'50vw'}:{}} >


              <h2 style={isMobile ? { width: "150%" } : {}} >New Product</h2>
              <form onSubmit={handleSubmit}  style={isMobile ? { width: "150%" } : {}} >
                <div className="form-row" >
                  {/* <div className=" col-lg-6">
                              <input  onChange={handleChange} type="text" className="form-control" name="productId" value={data.productId} id="productId" placeholder="Product Id"/>
                            </div> */}
                  <div className=" col-lg-12">
                    <input onChange={handleChange} type="text" className="form-control" name="productName" value={data.productName} id="productName" placeholder="Product Name" />
                  </div>
                  <div className=" col-lg-12 form-row">

                    <label className="col-lg-4 col-form-label">Product Images</label>
                    <div className="col-lg-2" >
                    <FileBase64
                      type='file'
                      multiple={false}
                      onDone={({ base64 }) => setData({ ...data, productImages: [base64, ...data.productImages] })}
                    />
                    </div >
                    <div className="col-lg-6">

                    </div>
                  </div>
                  <div className=" col-lg-12">
                    <input onChange={handleChange} type="text" className="form-control" name="description" value={data.description} id="description" placeholder='Description' />
                  </div>

                  <div className=" col-lg-6 mb-3">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Category</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={data.category}
                        label="category"
                        onChange={handleChange}
                        name="category"
                      >
                        {categories.map((c) => <MenuItem key={c._id} value={c.categoryName}>{c.categoryName}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </div>
                  <div className=" col-lg-6 mb-3">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Sub Category</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={data.subCategory}
                        label="subCategory"
                        onChange={handleChange}
                        name="subCategory"
                      >
                        {subCategories.map((c) => (c.categoryName === data.category) && (<MenuItem key={c._id} value={c.subCategoryName}>{c.subCategoryName}</MenuItem>))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className=" col-lg-6 mb-3">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Status</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={data.status}
                        label="status"
                        onChange={handleChange}
                        name="status"
                      >
                        <MenuItem value={"Active"}>Active</MenuItem>
                        <MenuItem value={"Inactive"}>Inactive</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className=" col-lg-6 mb-3">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Featured</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={data.featured}
                        label="featured"
                        onChange={handleChange}
                        name="featured"
                      >
                        <MenuItem value={"true"}>true</MenuItem>
                        <MenuItem value={"false"}>false</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className=" col-lg-6">
                    <input onChange={handleChange} type="text" className="form-control" name="address" value={data.address} id="address" placeholder='Address' />
                  </div>
                  <div className=" col-lg-6">
                    <input onChange={handleChange} type="number" min={0} className="form-control" name="noOfStock" value={data.noOfStock} id="noOfStock" placeholder='Number Of Stock' />
                  </div>
                  <div className=" col-lg-6">
                    <input onChange={handleChange} type="number" min={0} className="form-control" name="costPerWeek" value={data.costPerWeek} id="costPerWeek" placeholder='Cost per Week' />
                  </div>
                  <div className=" col-lg-6">
                    <input onChange={handleChange} type="number" min={0} className="form-control" name="costPerMonth" value={data.costPerMonth} id="costPerMonth" placeholder='Cost per Month' />
                  </div>

                  <div className=" col-lg-6">
                    <input onChange={handleChange} type="text" className="form-control" min={0} name="deposit" value={data.deposit} id="deposit" placeholder='Deposit' />
                  </div>
                  <div className=" col-lg-6">
                    <input onChange={handleChange} type="text" className="form-control" min={0} name="deliveryCharges" value={data.deliveryCharges} id="deliveryCharges" placeholder='Delivery Charges' />
                  </div>
                  <div className=" col-lg-12">
                    <input onChange={handleChange} type="text" className="form-control" name="brand" value={data.brand} id="brand" placeholder='Brand' />
                  </div>
                  {/* <input multiple onChange={handleChange} className="col-lg-9" type="file" accept='image/*' name="aadharCard" value={data.aadharCard} id="aadharCard" style={{ borderLeftWidth: "0px", borderTopWidth: "0px", borderRightWidth: "0px", borderBottomWidth: "0px", marginBottom: "0px" }} /> */}
                </div>
                <FormControlLabel control={<Checkbox checked={checked} onChange={() => setChecked(!checked)} />} label="Available" />
                <button type="submit" className="btn btn-primary">Submit</button>
              </form>
          </div>
          </div>
                        </div>
        </div>
      </section>
    </>
  )
}

export default ProductForm
