import React from 'react'
import Banner from './Banner'
// import MostlyRentedProducts from './MostlyRentedProducts'
import RentalCategories from './RentalCategories'

const Home = () => {
  return (
    <>
        <Banner/>
        <RentalCategories/>
        {/* <MostlyRentedProducts/> */}
    </>
  )
}

export default Home
