import * as api from '../api';
import { END_LOADING, GET_BY_SEARCH,  GET_PRODUCT, GET_PRODUCTS, GET_PRODUCT_NAMES, START_LOADING } from '../constants/actionTypes';


export const getProduct = (id) => async(dispatch) =>{
    try{
        // console.log("hello1")
        dispatch({type: START_LOADING});
        console.log(id)
        const {data} = await api.getProduct(id);
        // dispatch(action)
        console.log(data)
        dispatch({type:GET_PRODUCT,payload:data});
        dispatch({type: END_LOADING});
        // We store the data in payload
    }
    catch(err)
    {
        console.log(err);
    }
}

// export const getMostlyRentedProducts = (id) => async(dispatch) =>{
//     try{
//         dispatch({type:START_LOADING})
//         const {data} = await api.getMostlyRentedProducts();
//         // console.log(data)
//         dispatch({type:GET_MOSTLY_RENTED_PRODUCTS,payload:data});
//         dispatch({type:END_LOADING})
//         // We store the data in payload
//     }
//     catch(err)
//     {
//         console.log(err.message);
//     }
// }


export const getProducts = () => async(dispatch) => {
    try{
        dispatch({type:START_LOADING})
        const {data} = await api.getProducts();
        console.log(data)
        dispatch({type:GET_PRODUCTS,payload:data});
        dispatch({type:END_LOADING})
        // We store the data in payload
    }
    catch(err)
    {
        console.log(err);
    }
}


export const getProductNames = () => async(dispatch) => {
    try{
        dispatch({type:START_LOADING})
        const {data} = await api.getProductNames();
        dispatch({type:GET_PRODUCT_NAMES,payload:data});
        dispatch({type:END_LOADING})
        // We store the data in payload
    }
    catch(err)
    {
        console.log(err);
    }
}

export const getProductsBySearch = (searchQuery) => async(dispatch) =>{
    try {
        dispatch({type: START_LOADING});
        const {data} = await api.getProductsBySearch(searchQuery);
        console.log(data)
        dispatch({type:GET_BY_SEARCH,payload:data});
        dispatch({type: END_LOADING});
    } catch (error) {
        console.log(error)
    }
}


