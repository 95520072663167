import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { postSupplier } from '../actions/postData'
import { useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom";


const SupplierForm = () => {
  const user = JSON.parse(localStorage.getItem('profile'))
  const navigate = useNavigate()
  useEffect(()=>{
    if(!user || !user.result)
    navigate('/login')
  },[])
  const dispatch = useDispatch()

  const initialState = {
    status: "",
    gender: "",
    dob: "",
    occupation: "",
    primaryAddress: "",
    secondaryAddress: "",
    aadharNo: "",
    panNo: "",
    email: user?.result?.email,
    username: user?.result?.username,
    mobileNo: user?.result?.mobileNumber,
    kycStatus: "true"
  }


  const [data, setData] = useState(initialState)

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    if(Object.values(data).includes('')) 
     alert('Please fill all the values')
    else{

      // console.log(data)
      dispatch(postSupplier(data,setData))
      setData(initialState)
      navigate('/')
    }
  }

   const [width, setWidth] = useState(window.innerWidth);

const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
}
useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
}, []);

const isMobile = width <= 700;


  return (
    <>
      <br />
      <section >
        <div className="container">
          <div className="sign-form px-auto" style={isMobile?{border:'none',marginLeft:'7%',marginRight:'7%',width:'50vw'}:{}} >
            <h2 style={isMobile ? { width: "150%" } : {}} >Supplier Details</h2>
            <form onSubmit={handleSubmit} style={isMobile ? { width: "150%" } : {}} >
              <div className="form-row" >
                {/* <div className=" col-lg-12">
                            <input style={{color:'#333'}} type="text" className="form-control" name="supplierId" onChange={handleChange} value={data.supplierId} id="supplierId" placeholder="Supplier Id"/>
                          </div> */}
                <div className=" col-lg-6">
                  <input style={{color:'#333'}} type="text" className="form-control" name="occupation" onChange={handleChange} value={data.occupation} id="occupation" placeholder="Occupation" />
                </div>
                <div className=" col-lg-6">
                  <input style={{color:'#333'}} type="date" className="form-control" name="dob" onChange={handleChange} value={data.dob} id="dob" placeholder='Date Of Birth' />
                </div>
              </div>
              <div className="">
                <input style={{color:'#333'}} type="text" className="form-control" name="primaryAddress" onChange={handleChange} value={data.primaryAddress} id="primaryAddress" placeholder="Primary Address" />
              </div>
              <div className="">
                <input style={{color:'#333'}} type="text" className="form-control" name="secondaryAddress" onChange={handleChange} value={data.secondaryAddress} id="secondaryAddress" placeholder="Secondary Address" />
              </div>
              <div className="form-row mb-0" >
                <div className=" col-lg-6">
                  <input style={{color:'#333'}} type="text" className="form-control" name="aadharNo" onChange={handleChange} value={data.aadharNo} id="aadharNo" placeholder='Aadhar No' />
                </div>
                <div className=" col-lg-6">
                  <input style={{color:'#333'}} type="text" className="form-control" name="panNo" onChange={handleChange} value={data.panNo} id="panNo" placeholder='Pan No' />
                </div>
                <div className=" col-lg-6 mb-3">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={data.status}
                      label="status"
                      onChange={handleChange}
                      name="status"
                    >
                      <MenuItem value={"Active"}>Active</MenuItem>
                      <MenuItem value={"Inactive"}>Inactive</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className=" col-lg-6 mb-3">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={data.gender}
                      label="gender"
                      onChange={handleChange}
                      name="gender"
                    >
                      <MenuItem value={"Male"}>Male</MenuItem>
                      <MenuItem value={"Female"}>Female</MenuItem>
                      <MenuItem value={"Other"}>Other</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label" style={{fontWeight:'bolder'}} >Username</label>
                <label className="col-sm-9 col-form-label">{user?.result?.username}</label>
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label" style={{fontWeight:'bolder'}} >Email</label>
                <label className="col-sm-9 col-form-label">{user?.result?.email}</label>
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label" style={{fontWeight:'bolder'}} >Mobile No</label>
                <label className="col-sm-9 col-form-label">{user?.result?.mobileNumber}</label>
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label" style={{fontWeight:'bolder'}} >KYC Status</label>
                <label className="col-sm-9 col-form-label">{data.kycStatus}</label>
              </div>
              <button type="submit" className="btn btn-primary">Sign in</button>
            </form>
          </div>
        </div>
      </section>
    </>
  )
}

export default SupplierForm
