import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import Products from './Products'
import { useNavigate, useParams } from 'react-router-dom'
import { getProduct } from '../actions/product'
import { Button, InputLabel, IconButton, NativeSelect, P, StepLabelaper, StepLabel } from '@mui/material'
// import { addItemsToCart } from '../actions/cart'
import Carousel from "react-material-ui-carousel";
import ProductForm from './ProductForm'
import { getCategories, getSubCategories } from '../actions/fetchData'
import { postProduct, updateProduct } from '../actions/postData'
import { Checkbox, CircularProgress, FormControl, FormControlLabel,  MenuItem, Select } from '@mui/material'
import FileBase64 from 'react-file-base64'
import DeleteIcon from '@mui/icons-material/Delete';


const SingleProduct = () => {
  const { productId } = useParams()
  const navigate = useNavigate()

  const dispatch = useDispatch()
  const { isLoading, product } = useSelector((state) => (state.products))
  const { subCategories, categories } = useSelector((state) => state.category)
  const user = JSON.parse(localStorage.getItem('profile'))

  useEffect(() => {
    if (!user || !user.result)
      navigate('/login')

  }, [])
  useEffect(() => {
    !categories.length && dispatch(getCategories())
    !subCategories.length && dispatch(getSubCategories())
    dispatch(getProduct(productId))
  }, [productId])

  const [data, setData] = useState(product)
  const [checked, setChecked] = useState(data.availability === 'In Stock')

  // data.subCategory = subCategories?.find((c) => (c.categoryName === data.category))
  // data.subCategory = data.subCategory?.subCategoryName

  // useEffect(()=>{
  //   subC = subCategories?.find((c) => (c.categoryName === data.category))
  //   setData({...data,subCategory: subC?.subCategoryName})
  // },[data.category])


  useEffect(() => {
    setData(product)
    setChecked(data.availability === "In Stock")
  }, [product])

  useEffect(()=>{
    setData({...data,availability:checked?"In Stock":"Out of Stock"})
  },[checked])

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
    console.log(e.target.name,e.target.value)
    console.log(data.subCategory)
  }

  // console.log(data)

  const handleSubmit = (e) => {
    e.preventDefault()


    try {

      if (
        !data.productName ||
        !data.availability ||
        !data.category ||
        !data.subCategory ||
        !data.address ||
        !data.noOfStock ||
        !data.costPerWeek ||
        !data.costPerMonth ||
        !data.productImages ||
        !data.description ||
        !data.status ||
        !data.brand ||
        !data.deposit ||
        !data.deliveryCharges ||
        !data.featured
      ) {
        alert("All fields must be filled")
        console.log(data)
      }
      else {
        console.log(data)
        dispatch(updateProduct(data, product._id , setData))
        setData(product)
        navigate(-1)
        // setProductImages([])
        // setImages([])
      }
    } catch (error) {
      console.log(error)
    }
  }
  const handleImageDelete = (item) => {

    setData({ ...data, productImages: data.productImages.filter((i)=>i!==item) })
    // console.log(data.productImages)
  }
  

  return (<>
    {!product || !categories || !subCategories ? <CircularProgress /> : (<>
      {/* <!-- ======= Breadcrumbs Section ======= --> */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h1 className="breadcrumb-title">{product.productName}</h1>
            <ol>
              <li>
                <a href="/">Home</a>
              </li>
              <li>{product.productName}</li>
            </ol>
          </div>
        </div>
      </section>
      {/* <!-- Breadcrumbs Section --> */}

      {/* <!-- Shop Details Section Begin --> */}
      <section className="shop-details">
        <div className="product__details__content">
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-md-4 py-4">
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  {data.productImages && data.productImages.map((item, i) => (
                    <>
                      <div style={{ display: "flex" }}>

                        <img
                          style={{ width: '50vh', margin: '5px' }}
                          key={i}
                          src={item?.url || item}
                          alt={`${i} Slide`}
                        />
                        <IconButton color="error" sx={{ borderRadius: 0 }} onClick={() => handleImageDelete(item)}> <DeleteIcon /> </IconButton>
                      </div>
                    </>


                  ))}
                </div>
              </div>
              <div className="col-md-8 py-4">

                <section >
                  <div className="container">
                    <div className='sign-form px-2 mr-0' >


                      <h2>Product Details</h2>
                      <form onSubmit={handleSubmit}>
                        <div className="form-row" >
                          {/* <div className=" col-lg-6">
                              <input  onChange={handleChange} type="text" className="form-control" name="productId" value={data.productId} id="productId" placeholder="Product Id"/>
                            </div> */}
                          <div className=" col-lg-12">
                            <label>Product Name</label>
                            <input onChange={handleChange} type="text" className="form-control" name="productName" value={data.productName} id="productName" placeholder="Product Name" />
                          </div>
                          <div className=" col-lg-12 form-row">

                            <label className="col-lg-4 col-form-label">Product Images</label>
                            <div className="col-lg-2" >
                              <FileBase64
                                type='file'
                                multiple={false}
                                onDone={({ base64 }) => setData({ ...data, productImages: [base64, ...data.productImages] })}
                              />
                            </div >
                            <div className="col-lg-6">

                            </div>
                          </div>
                          <div className=" col-lg-12">
                            <label>Description</label>
                            <input onChange={handleChange} type="text" className="form-control" name="description" value={data.description} id="description" placeholder='Description' />
                          </div>

                          <div className=" col-lg-6 mb-3">
                            <label>Category</label>
                            <FormControl fullWidth>
                              <NativeSelect
                                defaultValue={data.category}
                                onChange={handleChange}
                                inputProps={{
                                  name: 'category',
                                  id: 'category',
                                }}
                              >
                                {categories.map((c) => <option key={c._id} value={c.categoryName}>{c.categoryName}</option>)}
                              </NativeSelect>
                            </FormControl>
                          </div>
                          <div className=" col-lg-6 mb-3">
                          <label>Sub Category</label>
                            <FormControl fullWidth>
                              <NativeSelect
                                defaultValue={data.subCategory}
                                onChange={handleChange}
                                inputProps={{
                                  name: 'subCategory',
                                  id: 'subCategory',
                                }}
                              >
                                {subCategories.map((c) => (c.categoryName === data.category) && (<option key={c._id} value={c.subCategoryName}>{c.subCategoryName}</option>))}

                              </NativeSelect>
                            </FormControl>
                          </div>
                          <div className=" col-lg-6">
                            <FormControl fullWidth>
                              <InputLabel id="status">Status</InputLabel>
                              <NativeSelect
                                defaultValue={data.status}
                                onChange={handleChange}
                                inputProps={{
                                  name: 'status',
                                  id: 'status',
                                }}
                              >
                                <option value={"Active"}>Active</option>
                                <option value={"Inactive"}>Inactive</option>
                              </NativeSelect>

                            </FormControl>
                          </div>
                          <div className=" col-lg-6 mb-3">
                            <FormControl fullWidth>
                              <InputLabel id="featured">Featured</InputLabel>
                              <NativeSelect
                                defaultValue={data.featured}
                                onChange={handleChange}
                                inputProps={{
                                  name: 'featured',
                                  id: 'featured',
                                }}
                              >
                                <option value={"true"}>True</option>
                                <option value={"false"}>False</option>
                              </NativeSelect>
                            </FormControl>
                          </div>
                          <div className=" col-lg-6">
                            <label>Address</label>
                            <input onChange={handleChange} type="text" className="form-control" name="address" value={data.address} id="address" placeholder='Address' />
                          </div>
                          <div className=" col-lg-6">
                            <label>Number</label>
                            <input onChange={handleChange} type="number" min={0} className="form-control" name="noOfStock" value={data.noOfStock} id="noOfStock" placeholder='Number Of Stock' />
                          </div>
                          <div className=" col-lg-6">
                            <label>Cost per Week</label>
                            <input onChange={handleChange} type="number" min={0} className="form-control" name="costPerWeek" value={data.costPerWeek} id="costPerWeek" placeholder='Cost per Week' />
                          </div>
                          <div className=" col-lg-6">
                            <label>Cost per Month</label>
                            <input onChange={handleChange} type="number" min={0} className="form-control" name="costPerMonth" value={data.costPerMonth} id="costPerMonth" placeholder='Cost per Month' />
                          </div>

                          <div className=" col-lg-6">
                            <label>Deposit</label>
                            <input onChange={handleChange} type="text" className="form-control" min={0} name="deposit" value={data.deposit} id="deposit" placeholder='Deposit' />
                          </div>
                          <div className=" col-lg-6">
                            <label>Delivery</label>
                            <input onChange={handleChange} type="text" className="form-control" min={0} name="deliveryCharges" value={data.deliveryCharges} id="deliveryCharges" placeholder='Delivery Charges' />
                          </div>
                          <div className=" col-lg-12">
                            <InputLabel>Brand</InputLabel>
                            <input onChange={handleChange} type="text" className="form-control" name="brand" value={data.brand} id="brand" placeholder='Brand' />
                          </div>
                        </div>
                        <FormControlLabel control={<Checkbox checked={checked} onChange={() => setChecked(!checked)} />} label="Available" />
                        <button type="submit" className="btn btn-primary">Submit</button>
                      </form>
                    </div>
                  </div>
                </section>

              </div>
            </div>
          </div>
        </div>
      </section>

    </>)}
  </>
  )
}

export default SingleProduct
